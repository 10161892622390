import { useEffect, useState } from "react";
import { axiosInstance } from "utils/request";
import z from "zod";

const SingleSignOnConfig = z.array(
  z.object({
    provider: z.enum(["google", "azure"]),
    googleData: z
      .object({
        id: z.number(),
        clientId: z.string(),
      })
      .optional(),
    azureData: z
      .object({
        id: z.number(),
        clientId: z.string(),
        authority: z.string(),
        redirectUri: z.string(),
        label: z.string(),
      })
      .optional(),
    internal: z.boolean(),
  })
);

type ISingleSignOnOption = Awaited<ReturnType<typeof getSingleSignonConfig>>;

async function getSingleSignonConfig() {
  const response = await axiosInstance.get("/auth/oauth");
  const data = SingleSignOnConfig.parse(response.data);
  return data;
}

export function useSingleSignOnConfigs() {
  const [configs, setConfigs] = useState<ISingleSignOnOption>([]);

  async function getConfigs() {
    const data = await getSingleSignonConfig();
    setConfigs(data);
  }

  useEffect(() => {
    getConfigs();
  }, []);

  return configs;
}
