import MenuItem from "components/MenuItem";
import { Input } from "@mui/material";
import { setMaxImageDistanceToMoveMap } from "state/actions";
import { useDispatch, useLanguage, useSelector } from "hooks";
import translations from "translations";

export default function MaxImageDistanceToMoveMap() {
  const dispatch = useDispatch();
  const maxImageDistance = useSelector(
    (state) => state.user.maxImageDistanceToMoveMap
  );
  const { language } = useLanguage();

  return (
    <MenuItem id="zoomSpeed" passive>
      <p>{translations.Menu.Settings.MaxDistanceBeforeMoving[language]}</p>
      <Input
        style={{
          width: 66,
          marginRight: 10,
        }}
        type="number"
        value={maxImageDistance}
        onChange={(e) => {
          const value = e.target.value;
          const numberValue = parseInt(value);
          dispatch(setMaxImageDistanceToMoveMap(numberValue));
        }}
      />
    </MenuItem>
  );
}
