import React, { useEffect } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { RootState } from "state/store";
import {
  loadSettings,
  getMissions,
  getClientIssueSeverities,
  getClientObjects,
  setListView,
  setInitialURL,
} from "state/actions";
import { HOCAuthenticator } from "authentication";
import "./App.scss";
import routes from "routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IAnalyticsPayload, useAnalytics } from "hooks/useAnalytics";
import { useFilterCookie } from "hooks/filter/useFilterCookie";

type State = { a: string }; // your state type
type AppDispatch = ThunkDispatch<State, void, AnyAction>;

const _ = require("lodash");

interface IProps {
  children: React.ReactNode;
}

function App({ children }: IProps) {
  const location = useLocation();
  const params = useParams();
  const [analyticsStarted, setAnalyticsStarted] = React.useState(false);
  const groups = useSelector((state: RootState) => state.group.groups);
  const missionId = useSelector((state: RootState) => state.mission.id);
  const dispatch: AppDispatch = useDispatch();
  const sendAnalyticsEvent = useAnalytics();

  useFilterCookie();

  //get clinet issues severities on first app load and when changing mission
  useEffect(() => {
    if (missionId > 0 && groups.length > 0) {
      // @ts-ignore
      const missionGroup = _.flatMap(groups, (item) =>
        _(item.missions)
          // @ts-ignore
          .filter((item2) => item2.id === missionId)
          // @ts-ignore
          .map((v) => item.id)
          .value()
      )[0];

      dispatch(getClientObjects(missionGroup));
      dispatch(getClientIssueSeverities(missionGroup));
    }
    dispatch(setListView(false));
  }, [missionId, groups, dispatch]);

  React.useEffect(() => {
    if (!analyticsStarted) return;
    // Google Analytics
    const matchedRoutes = matchRoutes(routes, location);
    // @ts-ignore
    const pathWithoutParams = matchedRoutes
      .reduce((acc, route) => {
        if (acc.endsWith("/")) {
          return `${acc}${route.route.path}`;
        } else {
          return `${acc}/${route.route.path}`;
        }
      }, "")
      .replaceAll("//", "/");

    const payload: IAnalyticsPayload = {};
    for (const key of Object.keys(params)) {
      // @ts-ignore
      payload[key] = params[key];
    }

    sendAnalyticsEvent("page_view", {
      pathWithoutParams,
      ...payload,
    });
  }, [location, params, analyticsStarted]);

  // Add legacy redirect
  useEffect(() => {
    dispatch(setInitialURL(window.location.pathname));
    const uriQuery = new URLSearchParams(window.location.search);
    const legacyImageParameters = uriQuery.get("ids");
    if (legacyImageParameters) {
      uriQuery.delete("ids");
      uriQuery.delete("images");
      let newQuery = uriQuery.toString();
      if (newQuery.length > 0) {
        newQuery = `?${newQuery}`;
      }
      window.location.replace(
        `${window.location.pathname}/${legacyImageParameters}${newQuery}`
      );
    }

    const legacyImageParameter = uriQuery.get("image");
    if (legacyImageParameter) {
      uriQuery.delete("image");
      let newQuery = uriQuery.toString();
      if (newQuery.length > 0) {
        newQuery = `?${newQuery}`;
      }
      window.location.replace(
        `${window.location.pathname}/${legacyImageParameter}${newQuery}`
      );
    }
  }, []);

  useEffect(() => {
    dispatch(
      loadSettings((settings) => {
        setAnalyticsStarted(true);
        sendAnalyticsEvent("login", {
          arkionEmployee: settings.skyqraft_employee,
          superuser: settings.superuser,
          userId: settings.id,
          userEmailDomain: settings.email.split("@")[1],
          language: settings.language,
        });
      })
    );
    dispatch(getMissions());
  }, [dispatch]);

  return children;
}

export default HOCAuthenticator(App);
