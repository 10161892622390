import {
  createRoutesFromElements,
  Outlet,
  Route,
  useOutletContext,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  MainLayout,
  MapView,
  ImageViewer,
  AnnotationToolOld,
  MachineReview,
  TruePositiveBbReview,
  FalsePositiveImageReview,
  Statistics,
  PowerlinePanel,
  BlueifyPowerlines,
  DividePowerlines,
  BlurAllImages,
  KMLUpload,
  KMLDownload,
  ZoneUpload,
  Upload,
  Report,
  Poles,
  ImageObjectUpload,
  ImageUpload,
  InventoryReport,
  DefectReport,
  AssetReport,
  JobUploadView,
  UsageReport,
  FolderUploadView,
  Inbox,
  MissionLanding,
  Landing,
  UserAdministrator,
  ProjectAdministrator,
  ImageReport,
} from "views";
import MissionInbox from "views/Inbox/MissionInbox";
import ObjectTypeManager from "components/ObjectTypeManager";
import ClientSettings from "components/ClientSettings";
import ReportPopupPDF from "views/Inbox/ReportPopupPDF";
import NewReport from "views/Inbox/NewReport";

import { AnnotationTool } from "views/AnnotationTool";
import AddPlacement from "views/options/pole/status/ComponentOptions/AddPlacements";
import UserPage from "views/UserAdministrator/UserPage";
import UserMissionPage from "views/UserAdministrator/UserMissionPage";
import UserGroupPage from "views/UserAdministrator/UserGroupPage";
import NewAccess from "views/UserAdministrator/NewAccess";
import NewUser from "views/UserAdministrator/NewUser";
import FAQ from "views/FAQ";
import ProjectPage from "views/ProjectAdministrator/ProjectPage";
import RegionPage from "views/ProjectAdministrator/RegionPage";
import ProjectGroupPage from "views/ProjectAdministrator/GroupPage";
import NewGroup from "views/ProjectAdministrator/NewGroup";
import NewRegion from "views/ProjectAdministrator/NewRegion";
import PoleCatcher from "components/PoleCatcher";
import PoleGroupCatcher from "components/PoleGroupCatcher";
import FalsePositiveBbReview from "views/image/Review/ReviewModes/FalsePositiveBbReview";
import NoReview from "views/image/NoReview/NoReviewContainer";
import EditCustomerGroup from "views/ProjectAdministrator/EditCustomerGroup";
import { VegetationReport } from "views/Report/VegetationReport";
import WorkSessionReport from "views/Report/WorkSessionReport";
import { PoleStatusOptions } from "views/options";
import TemplateOptions from "views/options/pole/status/TemplateOptions";
import EditTemplate from "views/options/pole/status/TemplateOptions/EditTemplate";
import TemplateOptionsProvider from "views/options/pole/status/TemplateOptions/provider";
import ComponentOptions, {
  AddComponent,
  ComponentOptionsProvider,
  EditComponent,
} from "views/options/pole/status/ComponentOptions";
import EditPlacement from "views/options/pole/status/ComponentOptions/EditPlacement";
import { Stack } from "@mui/material";
import { useTranslation } from "hooks";
import App from "App";
import DomainManager, {
  featureLocation as domainManagerLocation,
  DomainPage,
} from "features/DomainManager";
import ErrorPage from "components/ErrorPage";

function ClearComponent() {
  const context = useOutletContext();
  return <Outlet context={context} />;
}

function useATabAbove() {
  const clickATabAbove = useTranslation("ClickOnATabAbove");
  return <h2>{clickATabAbove}</h2>;
}

function Redirect(args: { from: string; to: string }) {
  const { from, to } = args;

  const location = useLocation();

  const newUrl = location.pathname.replace(from, to);

  return <Navigate to={`${newUrl}${location.search}`} replace />;
}

const routes = createRoutesFromElements(
  <Route
    path="*"
    element={
      <App>
        <MainLayout />
      </App>
    }
  >
    <Route index element={<Landing />} />
    <Route path="faq" element={<FAQ />} />
    <Route path="faq/*" element={<FAQ />} />
    <Route path="sv/faq" element={<FAQ />} />
    <Route path="sv/faq/*" element={<FAQ />} />
    <Route path=":mission/landing" element={<MissionLanding />} />
    <Route path="pole/:group/:pole" element={<PoleGroupCatcher />} />
    <Route path=":mission/inbox" element={<Inbox />}>
      <Route index element={<MissionInbox />} />
      <Route path=":report_id" element={<ReportPopupPDF />} />
      <Route path="new" element={<NewReport />} />
    </Route>
    <Route path="inbox" element={<Inbox />}>
      <Route index element={<MissionInbox />} />
      <Route path="new" element={<NewReport />} />
    </Route>
    <Route
      path={domainManagerLocation}
      element={<DomainManager />}
      errorElement={<ErrorPage />}
    >
      <Route index element={<DomainPage />} errorElement={<ErrorPage />} />
      <Route
        path=":domain"
        element={<DomainPage />}
        errorElement={<ErrorPage />}
      />
    </Route>
    {/*@ts-ignore*/}
    <Route path=":mission" element={<MapView />}>
      <Route path="pole/:pole" element={<PoleCatcher />} />
      <Route path=":image" element={<ImageViewer />}>
        {/*@ts-ignore*/}
        <Route path="annotate-dev" element={<AnnotationToolOld />} />
        <Route path="annotate" element={<AnnotationTool />} />
        <Route index element={<NoReview />} />

        <Route
          path="machine-review"
          element={<Redirect from="machine-review" to="verify-ml" />}
        />
        <Route path="verify-ml" element={<Outlet />}>
          <Route index element={<MachineReview />} />
          <Route path="annotate" element={<AnnotationTool />} />
        </Route>

        <Route
          path="supervisor-review"
          element={
            <Redirect from="supervisor-review" to="super-verify-tp-bbs" />
          }
        />
        <Route path="super-verify-tp-bbs">
          <Route index element={<TruePositiveBbReview />} />
          <Route path="annotate" element={<AnnotationTool />} />
        </Route>

        <Route
          path="falsepositive-review"
          element={
            <Redirect from="falsepositive-review" to="super-verify-fp-images" />
          }
        />
        <Route path="super-verify-fp-images">
          <Route index element={<FalsePositiveImageReview />} />
          <Route path="annotate" element={<AnnotationTool />} />
        </Route>

        <Route
          path="superfalsepositive-review"
          element={
            <Redirect
              from="superfalsepositive-review"
              to="super-verify-fp-bbs"
            />
          }
        />
        <Route path="super-verify-fp-bbs">
          <Route index element={<FalsePositiveBbReview />} />
          <Route path="annotate" element={<AnnotationTool />} />
        </Route>

        <Route path="thermal" element={<ClearComponent />} />
      </Route>

      <Route path="powerline" element={<PowerlinePanel />}>
        <Route index element={useATabAbove} />
        <Route path="bluify" element={<BlueifyPowerlines />} />
        <Route path="divide" element={<DividePowerlines />} />
        <Route path="poles" element={<Poles />} />
        <Route path="download" element={<KMLDownload />} />
      </Route>

      <Route path="report" element={<Report />}>
        <Route index element={useATabAbove} />
        <Route path="object" element={<InventoryReport />} />
        <Route path="image" element={<ImageReport />} />
        {/*@ts-ignore*/}
        <Route path="defect" element={<DefectReport />} />
        <Route path="asset" element={<AssetReport />} />
        <Route path="missioninfo" element={<UsageReport />} />
        <Route path="worksession" element={<WorkSessionReport />} />
        <Route path="vegetation" element={<VegetationReport />} />
      </Route>

      <Route path="upload" element={<Upload />}>
        <Route index element={useATabAbove} />
        <Route path="powerlines" element={<KMLUpload />} />
        <Route path="zones" element={<ZoneUpload />} />
        <Route path="imageobjects" element={<ImageObjectUpload />} />
        <Route path="images" element={<ImageUpload />}>
          <Route index element={<FolderUploadView disabled />} />
          <Route path=":folder" element={<FolderUploadView />}>
            <Route path=":job" element={<JobUploadView />} />
          </Route>
        </Route>
      </Route>

      <Route path="imageupload" element={<ImageUpload />} />
      <Route path="statistics" element={<Statistics />} />
      <Route path="blur" element={<BlurAllImages />} />
    </Route>
    <Route path="statistics" element={<Statistics />} />
    <Route path="order" element={<MissionLanding />} />
    <Route path="group" element={<ProjectAdministrator />}>
      <Route path="new" element={<NewGroup />} />
      <Route path=":group" element={<ProjectGroupPage />}>
        {/*@ts-ignore*/}
        <Route index element={<EditCustomerGroup />} />
        <Route path="region" element={<ClearComponent />}>
          <Route path=":region" element={<RegionPage />} />
          <Route path="new" element={<NewRegion />} />
        </Route>
        <Route path="project" element={<ClearComponent />}>
          <Route path=":project" element={<ProjectPage />} />
        </Route>
      </Route>
    </Route>
    <Route path="users" element={<UserAdministrator />}>
      <Route path="new" element={<NewUser />} />
      <Route path=":user" element={<UserPage />}>
        <Route path="new" element={<NewAccess />} />
        <Route path="group/:user_group" element={<UserGroupPage />} />
        <Route path="mission/:user_mission" element={<UserMissionPage />} />
      </Route>
    </Route>
    {/*@ts-ignore*/}
    <Route path="objects" element={<ObjectTypeManager />} />
    {/*@ts-ignore*/}
    <Route path="client/settings" element={<ClientSettings />} />
    <Route path="options" element={<ClearComponent />}>
      <Route path="pole" element={<ClearComponent />}>
        <Route path="status" element={<PoleStatusOptions />}>
          <Route path="template" element={<TemplateOptionsProvider />}>
            <Route index element={<TemplateOptions />} />
            <Route path=":template_id" element={<TemplateOptions />}>
              <Route path="edit" element={<EditTemplate />} />
            </Route>
            <Route
              path=":template_id/component/:component_id"
              element={<TemplateOptions />}
            />
            <Route
              path=":template_id/component/:component_id/placement/:placement_id"
              element={<TemplateOptions />}
            />
          </Route>
          <Route path="component" element={<ComponentOptionsProvider />}>
            <Route
              index
              element={
                <ComponentOptions>
                  <Stack flex={1} />
                </ComponentOptions>
              }
            />
            <Route
              path="add"
              element={
                <ComponentOptions>
                  <AddComponent />
                </ComponentOptions>
              }
            />
            <Route
              path=":component_id"
              element={
                <ComponentOptions>
                  <EditComponent />
                </ComponentOptions>
              }
            >
              <Route path="edit" element={<EditComponent />} />
            </Route>
            <Route
              path=":component_id/placement"
              element={<ComponentOptions />}
            >
              <Route path="add" element={<AddPlacement />} />
              <Route path=":placement_id" element={<EditPlacement />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="report" element={<Report />}>
      <Route index element={<UsageReport />} />
      <Route path="missioninfo" element={<UsageReport />} />
    </Route>
    <Route path="oauth/redirect" element={<p>Redirected!</p>} />
  </Route>
);

export default routes;
