export function zoomToImage(
  map: google.maps.Map,
  lat: number,
  lng: number,
  imageViewerWidth: number,
  poleViewerWidth: number,
  shouldZoomIn = false,
  zoomLevel = 18
) {
  shouldZoomIn && map.setZoom(zoomLevel);
  map.panTo({ lat, lng });
  const poleViewerPercentage = poleViewerWidth / window.innerWidth;
  const panBy =
    -((imageViewerWidth - poleViewerPercentage) / 2) * window.innerWidth;
  map.panBy(panBy, 0);
}

export function haversine(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  const R = 6371e3; // metres
  const phi_1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const phi_2 = (lat2 * Math.PI) / 180;
  const d_phi = ((lat2 - lat1) * Math.PI) / 180;
  const d_delta = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(d_phi / 2) * Math.sin(d_phi / 2) +
    Math.cos(phi_1) *
      Math.cos(phi_2) *
      Math.sin(d_delta / 2) *
      Math.sin(d_delta / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
}
